/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';
import React from 'react';
import {
  Col, Container, Row,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';

import Icons from '../components/Icons';
import Page from '../components/Page';
import werkstattbuecher from '../content/werkstattbuecher';

const StyledContainer = styled(Container)`
  h1 {
    font-size: 2rem;
    text-align: center;
    margin: 5rem 0 1.5rem 0;
  }
  .book-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  @media screen and (min-width: 992px) {
    h1 {
      font-size: 3.75rem;
    }
  }
`;

const Book = styled.div`
  background: ${({ theme }) => theme.radialGradientBook};
  position: relative;
  width: 100%;
  padding: 3em;
  min-height: 21em;
  margin-bottom: 2rem;
  .title {
    font-weight: bold;
  }
  .img {
    margin-bottom: 1rem;
  }
  .order {
    cursor: pointer;
    padding: .375rem .75rem;
    border: 1px solid ${({ theme }) => theme.color};
    border-radius: .25rem;
    color: ${({ theme }) => theme.color};
    text-decoration: none;
    margin-top: 1rem;
    display: inline-flex;
    align-items: center;
    font-weight: bold;
    svg {
      margin-right: 0.5rem;
      path {
        fill: ${({ theme }) => theme.color};
      }
    }
    &:hover {
      color: ${({ theme }) => theme.highlightColor};
      border-color: ${({ theme }) => theme.highlightColor};
      text-shadow: 0 0 1em ${({ theme }) => theme.glowColor};
      box-shadow: 0 0 1em rgba(255, 236, 120, 0.3);
    }
    &:hover svg {
      path {
        fill: ${({ theme }) => theme.highlightColor};
      }
    }
  }
  @media screen and (min-width: 992px) {
    width: 45%;
    padding-left: 12em;
    .img {
      position: absolute;
      left: -2em;
    }
  }
`;

const Werkstattbuecher = ({ show }) => (
  <Page show={show}>
    <StyledContainer>
      <Row>
        <Col>
          <h1>Werkstattbücher</h1>
          <p className="text-center mb-5">
            Ingrid Jureit, Wielandstraße 24 B, 65719 Hofheim am Taunus
            <br className="d-none d-sm-inline" />
            {' '}
            Tel. 06192 - 26772
          </p>
          <div className="book-container">
            {werkstattbuecher.map((book) => (
              <Book key={book.key}>
                <div className="img">
                  <img src={`/img/buecher/${book.img}`} alt={book.title.replace(/<br \/>/g, '')} />
                </div>
                <div className="mb-3 title" dangerouslySetInnerHTML={{ __html: book.title }} />
                <div dangerouslySetInnerHTML={{ __html: book.text }} />
                <Link className="order" to={`/kontakt#${book.key}`}>
                  <Icons.Cart />
                  Bestellen
                </Link>
              </Book>
            ))}
          </div>
        </Col>
      </Row>
    </StyledContainer>
  </Page>
);

Werkstattbuecher.propTypes = {
  show: PropTypes.bool,
  theme: PropTypes.shape({}),
};

Werkstattbuecher.defaultProps = {
  show: false,
  theme: {},
};

export default withTheme(Werkstattbuecher);
