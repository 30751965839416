import HCaptcha from '@hcaptcha/react-hcaptcha';
import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Alert, Button, Form } from 'react-bootstrap';

import schema from '../schema/contact';
import Icons from './Icons';

const hcaptchaKey = process.env.REACT_APP_HCAPTCHA_KEY;

const ContactForm = ({
  errors,
  handleBlur,
  handleChange,
  handleSubmit,
  isSubmitting,
  isValid,
  setFieldValue,
  touched,
  values,
}) => {
  const captchaRef = React.useRef(null);
  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Form.Group controlId="contact.email">
        <Form.Label>Email</Form.Label>
        <Form.Control
          isInvalid={!!(touched.email && errors.email)}
          isValid={!!(touched.email && !errors.email)}
          name="email"
          onBlur={handleBlur}
          onChange={handleChange}
          type="email"
          value={values.email || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.email || ''}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="contact.subject">
        <Form.Label>Betreff</Form.Label>
        <Form.Control
          isInvalid={!!(touched.subject && errors.subject)}
          name="subject"
          onBlur={handleBlur}
          onChange={handleChange}
          type="text"
          value={values.subject || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.subject || ''}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="contact.message">
        <Form.Label>Mitteilung</Form.Label>
        <Form.Control
          as="textarea"
          isInvalid={!!(touched.message && errors.message)}
          name="message"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.message || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.message || ''}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mt-4" controlId="contact.consent">
        <Form.Check className="d-flex align-items-center">
          <Form.Check.Input
            isInvalid={!!(touched.consent && errors.consent)}
            label=""
            name="consent"
            onBlur={handleBlur}
            onChange={handleChange}
            type="checkbox"
            value={values.consent || ''}
          />
          <Form.Check.Label>
            Ich stimme der Verarbeitung meiner personenbezogenen Daten zu.
          </Form.Check.Label>
          <Form.Control.Feedback type="invalid">
            {errors.consent || ''}
          </Form.Control.Feedback>
        </Form.Check>
        <p className="mt-2">
          Ich stimme der Verarbeitung meiner personenbezogenen Daten zu und habe
          die Datenschutzerklärung gelesen.
          Wir nutzen Ihre hier angegeben personenbezogenen Daten nur zum Zweck der
          Beantwortung Ihrer Fragen [Anfrage] und geben Ihre Daten nicht an Dritte
          weiter. Resultiert aus dieser Anfrage keine Geschäftsanbahnung oder
          Geschäftsbeziehung, werden Ihre Daten nach Zweckerfüllung gelöscht. Sie
          können der Verarbeitung und Speicherung jederzeit widersprechen mit einer
          E-Mail an mail@ingridjureit.de.
        </p>
      </Form.Group>
      <HCaptcha
        sitekey={hcaptchaKey}
        onVerify={(token) => { setFieldValue('token', token); }}
        ref={captchaRef}
      />
      {touched.token && errors.token ? (<Alert variant="danger">{errors.token}</Alert>) : null}
      <Button disabled={!isValid || isSubmitting} type="submit" variant="primary">
        <Icons.Paperplane />
        Absenden
      </Button>
    </Form>
  );
};

ContactForm.propTypes = {
  errors: PropTypes.shape({
    consent: PropTypes.string,
    email: PropTypes.string,
    message: PropTypes.string,
    subject: PropTypes.string,
    token: PropTypes.string,
  }),
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
  isValid: PropTypes.bool,
  setFieldValue: PropTypes.func,
  touched: PropTypes.shape({
    consent: PropTypes.bool,
    email: PropTypes.bool,
    message: PropTypes.bool,
    subject: PropTypes.bool,
    token: PropTypes.bool,
  }),
  values: PropTypes.shape({
    consent: PropTypes.bool,
    email: PropTypes.string,
    message: PropTypes.string,
    subject: PropTypes.string,
    token: PropTypes.string,
  }),
};

ContactForm.defaultProps = {
  errors: {},
  handleBlur: null,
  handleChange: null,
  handleSubmit: null,
  isSubmitting: false,
  isValid: false,
  setFieldValue: null,
  touched: {},
  values: {},
};

export default withFormik({
  displayName: 'ContactForm',
  handleSubmit: (values, actions) => {
    const { onSubmit } = actions.props;
    return onSubmit(values, actions);
  },
  validateOnMount: true,
  mapPropsToValues: (props) => props.initialValues,
  validationSchema: schema.validationSchema,
})(ContactForm);
