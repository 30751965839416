/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';
import React from 'react';
import styled, { withTheme } from 'styled-components';

const TimelineContainer = styled.div`
  margin-top: 5rem;
  margin-left: 1.5rem;
  @media screen and (min-width: 992px) {
    margin-left: 0;
  }
`;

const Event = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  transition: background-color 0.2s;
  .date {
    border-left: ${({ theme }) => theme.timeline};
    padding-left: 2rem;
    padding-top: 1rem;
    position: relative;
    font-size: 1.5rem;
    font-weight: bold;
    transition: color 0.2s ease;
    .dot {
      position: absolute;
      top: 1.35rem;
      left: -1.25rem;
      width: 1.75rem;
      height: 1.75rem;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.color};
      transition: background-color 0.2s ease;
    }
  }
  .text {
    border-left: ${({ theme }) => theme.timeline};
    padding: 1rem 0 1rem 2rem;
    font-size: 1.2rem;
    p:last-child {
      margin-bottom: 0;
    }
  }
  &:hover {
    background-color: ${({ theme }) => theme.timelineHover};
  }
  @media screen and (min-width: 992px) {
    flex-direction: row;
    .date {
      border-left: none;
      padding-top: 0.8rem;
      padding-left: 0;
      width: 25%;
      text-align: right;
      padding-right: 2rem;
      .dot {
        top: 1.1rem;
        left: auto;
        right: -1.25rem;
      }
    }
    .text {
      width: 75%;
    }
  }
`;

const Timeline = ({ events }) => (
  <TimelineContainer>
    {events.map((event) => (
      <Event key={event.key}>
        <div className="date">
          {event.date}
          <div className="dot" />
        </div>
        <div
          className="text"
          dangerouslySetInnerHTML={{ __html: event.text }}
        />
      </Event>
    ))}
  </TimelineContainer>
);

Timeline.propTypes = {
  events: PropTypes.arrayOf(PropTypes.shape({})),
};

Timeline.defaultProps = {
  events: [],
};

export default withTheme(Timeline);
