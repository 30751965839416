import PropTypes from 'prop-types';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import styled from 'styled-components';
import bilder from '../content/bilder';
import Icons from './Icons';
// import LazyImage from './LazyImage';

const StyledModal = styled(Modal)`
  .modal-content {
  }
  .modal-body {
    text-align: center;
    img {
      max-height: 80vh;
    }
    .nav {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: bottom;
      justify-content: space-between;
      .btn {
        height: 100%;
        background-color: transparent;
        color: transparent;
        border: none;
        ${'' /* display: flex;
        align-items: center; */}
        font-size: 0.8rem;
        font-weight: bold;
        line-height: 1rem;
        svg {
          width: 2rem;
        }
        span {
          display: block;
          white-space: nowrap;
          color: transparent;
          text-transform: uppercase;
        }
        &:hover {
          background-color: white;
          color: rgba(0, 0, 0, 0.2);
          span {
            color: black;
          }
        }
        &:active,
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
      .prev {
        svg {
        }
        span {
        }
      }
      .next {
        svg {
        }
        span {
        }
      }
    }
  }
  .modal-footer {
    width: 100%;
    justify-content: space-between;
    font-weight: bold;
    > div > div {
      font-weight: normal;
      font-style: italic;
    }
  }
`;

const GalleryModal = ({
  activeImage, next, prev, onHide, show,
}) => {
  const image = bilder && bilder[activeImage - 1]
    ? bilder[activeImage - 1]
    : null;
  return (
    <StyledModal centered size="xl" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Bilder</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {image.src && (
          <img
            src={`/img/bilder/1920/${image.src}`}
            alt=""
          />
        )}
        <div className="nav">
          <Button className="prev" onClick={prev}>
            <Icons.Prev />
            <span>Zurück</span>
          </Button>
          <Button className="next" onClick={next}>
            <span>Weiter</span>
            <Icons.Next />
          </Button>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <div>Titel:</div>
          {image.title}
        </div>
        <div>
          <div>Größe:</div>
          {image.size}
        </div>
        <div>
          <div>Medium:</div>
          {image.medium}
        </div>
      </Modal.Footer>
    </StyledModal>
  );
};

GalleryModal.propTypes = {
  activeImage: PropTypes.number,
  next: PropTypes.func,
  prev: PropTypes.func,
  onHide: PropTypes.func,
  show: PropTypes.bool,
};

GalleryModal.defaultProps = {
  activeImage: 1,
  next: null,
  prev: null,
  onHide: null,
  show: false,
};

export default GalleryModal;
