import PropTypes from 'prop-types';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styled, { withTheme } from 'styled-components';

import Line from '../components/Line';
import Page from '../components/Page';

const StyledContainer = styled(Container)`
  .bordeux-column {
    background: ${({ theme }) => theme.pencilDrawing};
    background-size: cover;
    color: ${({ theme }) => theme.color};;
    padding: 2rem;
    .cite-author {
      font-size: 1.25rem;
      line-height: 1.428em;
    }
    .rembrand {
      font-size: 1.5rem;
      line-height: 1.16;
      text-shadow: ${({ theme }) => theme.textShadow};
      p {
        margin: 0 0 0.5em;
        max-width: 20em;
      }
    }
    .comment-ingrid {
      margin-top: 1em;
      text-align: center;
      font-size: 1.5rem;
      line-height: 1.25;
      .name {
        font-size: 1.2rem;
      }
    }
  }
  .right-column {
    padding: 2rem;
    background: ${({ theme }) => theme.radialGradient};
    .ingrid {
      text-align: center;
      img {
        max-width: 100%;
        border: 2em solid white;
      }
    }
  }
  @media screen and (min-width: 1200px) {
    .bordeux-column {
      padding: 5em 12em 5em 5em;
      height: 46em;
      .cite-author {
        font-size: 1.5rem;
      }
      .rembrand {
        font-size: 2rem;
        p {
          margin: 0 0 0.5em;
          max-width: 20em;
        }
      }
      .comment-ingrid {
        margin-top: 2em;
        text-align: right;
        font-size: 1.5rem;
        .name {
          font-size: 1.25rem;
        }
      }
    }
    .right-column {
      padding: 0;
      .ingrid {
        position: relative;
        img {
          max-width: 100%;
          position: absolute;
          top: 5em;
          left: -8em;
          border: 2em solid white;
        }
      }
    }
  }
  @media screen and (min-width: 1500px) {
    .right-column {
      .ingrid {
        img {
          top: 2em;
        }
      }
    }
  }
  @media screen and (min-width: 1600px) {
    .bordeux-column {
      padding: 8.5em 12em 8.5em 10em;
      height: 56em;
      .cite-author {
        font-size: 1.75rem;
      }
      .rembrand {
        font-size: 2.25rem;
      }
      .comment-ingrid {
        margin-top: 4em;
        font-size: 2rem;
        .name {
          font-size: 1.5rem;
        }
      }
    }
    .right-column {
      .ingrid {
        img {
          top: 5em;
        }
      }
    }
  }
`;

const StyledAboutMyWork = styled.div`
  background: ${({ theme }) => theme.radialGradientTwo};
  color: ${({ theme }) => theme.color};
  padding: 2em 0;
  font-size: 1.2rem;
  line-height: 1.5;
  h2 {
    font-size: 2.125rem;
    margin-bottom: 2rem;
  }
  img {
    margin-bottom: 1em;
  }
  @media only screen and (min-width: 992px) {
    padding: 5em 0;
    font-size: 1.35rem;
  }
`;

const Konzeption = ({ show }) => (
  <Page show={show}>
    <StyledContainer fluid>
      <Row>
        <Col className="bordeux-column" lg={8}>
          <div>
            <p className="cite-author">
              Zitat von Rembrandt (15.7.1606 – 14.10.1669) Amsterdam
              <br />
              Holländischer Maler, Zeichner und Radierer
            </p>
            <cite className="rembrand">
              <p>„Das Malen von menschlichen Figuren ist das Wesentlichste für einen Künstler.</p>
              <p>Unsere Aufgabe ist es nicht, den äußerlichen Glanz von Etwas zu verewigen.</p>
              <p>Ein Maler soll die Seele eines Menschen auf die Leinwand bannen!“</p>
            </cite>
          </div>
          <div className="comment-ingrid">
            <p>
              Dies war und ist für alle Zeiten
              <br className="d-none d-sm-inline" />
              {' '}
              die Basis meiner Kunst.
            </p>
            <p className="name">Ingrid Jureit</p>
          </div>
        </Col>
        <Col className="right-column" lg={4}>
          <div className="ingrid">
            <img src="/img/ingridjureit-konzeption.jpg" alt="Ingrid Jureit" />
          </div>
        </Col>
      </Row>
    </StyledContainer>
    <Line />
    <StyledAboutMyWork>
      <Container>
        <Row>
          <Col>
            <h2>Über meine Arbeit</h2>
          </Col>
        </Row>
        <Row>
          <Col lg={3}>
            <img className="d-none d-lg-block" src="/img/bei-der-arbeit.jpg" alt="Ingrid Jureit bei der Arbeit" />
            <img className="d-none d-lg-block" src="/img/im-atelier.jpg" alt="Ingrid Jureit im Atelier" />
          </Col>
          <Col lg={9}>
            <p>
              Meine intensiven Erfahrungen, den Menschen in seiner Gesamtheit, seinem Wesen, Leben,
              Leid und Schicksal mit all meinen Sinnen zu erkennen um darauf zu reagieren, standen
              schon immer im Mittelpunkt meiner Arbeit.
              <br />
              Es sind die Bilder und Informationen aus Politik und Umfeld die unseren Alltag prägen.
            </p>
            <img className="d-lg-none mt-3 mb-4" src="/img/bei-der-arbeit.jpg" alt="Ingrid Jureit bei der Arbeit" />
            <p>
              Nur über den Malvorgang kann ich meiner persönlichen Betroffenheit Ausdruck verleihen.
              Meine Arbeit lebt durch eine bewusste Offenheit, die Erinnerung von Ereignissen aus
              unserem Umfeld in die Gegenwart zu transportieren und über viele Schichten im Bild
              eindrücklich neu zu erleben.
            </p>
            <p>
              Dabei ist mir die Gestik und Körperhaltung der menschlichen Gestalt wichtig, sie sind
              der Ausdrucksträger meiner intensiven Wahrnehmungen, die sich wie Narben in die Haut
              gegraben haben und in zeitlichen Abläufen und Lebensabschnitten in einem Geflecht von
              Momentaufnahmen unaufhaltsam übereinander schieben und nach außen drängen.
            </p>
            <p>
              Meine Malerei ist eine konzentrierte Aufzeichnung von vergangenem Erleben, wie Freude,
              Glück, Trauer oder Angst und Erinnerungen, die niemals sprachlich geäußert werden
              können. Es ist der Versuch, meinem Mitgefühl eine Gestalt zu geben.
            </p>
            <p>
              Der Betrachter kann den Weg dahin, von der erlebten Geschichte bis zu dem endgültigen
              Bild teilweise aus eigener Erfahrung mitverfolgen, um dadurch vergleichbare
              Erinnerungen und Gefühle in sein Bewusstsein rufen zu können.
            </p>
            <p>
              Ingrid Jureit
            </p>
            <div className="d-lg-none mt-5">
              <img src="/img/im-atelier.jpg" alt="Ingrid Jureit im Atelier" />
            </div>
          </Col>
        </Row>
      </Container>
    </StyledAboutMyWork>
  </Page>
);

Konzeption.propTypes = {
  show: PropTypes.bool,
  theme: PropTypes.shape({}),
};

Konzeption.defaultProps = {
  show: false,
  theme: {},
};

export default withTheme(Konzeption);
